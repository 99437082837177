import React from 'react'
import { Table } from 'semantic-ui-react'
import Context from './context'
import SubCellTable from './subCellTable'
import moment from 'moment';
function TableExamplePagination(props) {
    return (
        <Context.Consumer >
            {
                ctx => {
                    // console.log(ctx.value.newData)
                    //  let random = Math.floor(Math.random() * colors.length)
                    let coloridx = 0
                    return (
                        <div>
                            {
                                ctx.value.newData.data.length > 0 ?
                                    ctx.value.newData.data.map(function (value, index) {
                                        let view = constructTable(value, index, ctx.value.fromDateVal, ctx.value.toDateVal,coloridx)
                                        coloridx++
                                        coloridx = coloridx % colors.length
                                        return view
                                    })
                                    :
                                    <Table celled className="myTable" color={"blue"} key={"colors[random]"} inverted>
                                        <Table.Header  >
                                            <Table.Row  >
                                                <Table.HeaderCell className="cellClass" colSpan='1'>اسم المركبة</Table.HeaderCell>
                                                <Table.HeaderCell className="cellClass" colSpan='2'>** لا يوجد **</Table.HeaderCell>
                                                <Table.HeaderCell className="cellClass" colSpan='1'>{
                                                    "بداية الفترة: "
                                                }</Table.HeaderCell>
                                                <Table.HeaderCell className="cellClass" colSpan='2'>{
                                                    "نهاية الفترة: "
                                                }</Table.HeaderCell>

                                            </Table.Row>
                                            <Table.Row>
                                                <Table.HeaderCell className="cellClass">التاريخ</Table.HeaderCell>
                                                <Table.HeaderCell className="cellClass">اليوم</Table.HeaderCell>
                                                <Table.HeaderCell className="cellClass">المسافة الكلية</Table.HeaderCell>
                                                <Table.HeaderCell className="cellClass">المسافة في وقت الدوام</Table.HeaderCell>
                                                <Table.HeaderCell className="cellClass">المسافة خارج وقت الدوام</Table.HeaderCell>
                                                <Table.HeaderCell className="cellClass">المسافة خارج ايام الدوام</Table.HeaderCell>
                                            </Table.Row>

                                        </Table.Header>

                                        <Table.Footer>
                                            <Table.Row>

                                            </Table.Row>
                                        </Table.Footer>
                                    </Table>
                            }
                        </div>
                    )
                }
            }
        </Context.Consumer>
    )
}

// const colors = [
//     'red',
//     'orange',
//     'yellow',
//     'olive',
//     'green',
//     'teal',
//     'blue',
//     'violet',
//     'purple',
//     'pink',
//     'brown',
//     'grey',
//  //   'black',
// ]

const colors = [
    // "#CCD1D1",
    // "#D5DBDB",
    // "#E5E7E9"
    "#0275d8",
    "#5bc0de",
   //"LightSteelBlue",
   
 //   "silver"
]

function constructTable(vehicle, index, from, to,coloridx) {
    let array = []
    // let view =
    let totalInHours = 0
    let totalOutHours = 0
    let totalOutDays = 0
    let footer = []
    for (let i = 0; i < vehicle.dates.length; i++) {
        // console.log(i)
        // console.log(vehicle.dates.length)
        //  console.log(vehicle.dates[i])
        let var1 = vehicle.dates[i].wInHkm.replace(/[^\d\.]*/g, '');
        let var2 = vehicle.dates[i].wOutHkm.replace(/[^\d\.]*/g, '');
        let var3 = vehicle.dates[i].wOutDkm.replace(/[^\d\.]*/g, '');
        var1 = isNaN(parseFloat(var1)) ? 0 : parseFloat(var1).toFixed(2)
        var2 = isNaN(parseFloat(var2)) ? 0 : parseFloat(var2).toFixed(2)
        var3 = isNaN(parseFloat(var3)) ? 0 : parseFloat(var3).toFixed(2)
        totalInHours += parseFloat(var1)
        totalOutHours += parseFloat(var2)
        totalOutDays += parseFloat(var3)
        //    console.log(totalInHours + " - " + totalOutHours + " - " + totalOutDays)
        // console.log(parseFloat(var1) + parseFloat(var2) + parseFloat(var3))
        array.push(
            <Table.Row
                //  style={vehicle.dates[i].weightNetWeight === "---" ? { color: '#ffffff', backgroundColor: "#ff0000" } : null}
                key={index}>
                {/* <Table.Cell key={index + vehicle.id + 'a'}>{index + 1}</Table.Cell> */}
                <Table.Cell className="cellClass" key={index + 'b'}>{vehicle.dates[i].date}</Table.Cell>
                {/* <Table.Cell className="cellClass" key={index + value.id + 'b2'}>{value.status}</Table.Cell> */}

                <Table.Cell className="cellClass" key={index + 'c'}>
                    <SubCellTable data={vehicle.dates[i].day}></SubCellTable>
                </Table.Cell>
                <Table.Cell className="cellClass" key={index + 'd'}>
                    <SubCellTable data={((parseFloat(var1) + parseFloat(var2) + parseFloat(var3)).toFixed(2)) + " km"}></SubCellTable>
                </Table.Cell>
                <Table.Cell className="cellClass" key={index + 'e'}>
                    <SubCellTable data={vehicle.dates[i].wInHkm}></SubCellTable>
                </Table.Cell>
                <Table.Cell className="cellClass" key={index + 'f'}>
                    <SubCellTable data={vehicle.dates[i].wOutHkm}></SubCellTable>
                </Table.Cell>
                <Table.Cell className="cellClass" key={index + 'g'}>
                    <SubCellTable data={vehicle.dates[i].wOutDkm}></SubCellTable>
                </Table.Cell>

            </Table.Row>
        )
        if (i === (vehicle.dates.length - 1)) {
            footer.push(
                <Table.Row
                    //  style={{ color: '#ffffff', backgroundColor: "#332211" }}
                    key={index}>
                    {/* <Table.Cell key={index + vehicle.id + 'a'}>{index + 1}</Table.Cell> */}
                    <Table.HeaderCell className="cellClass" colSpan='2' key={index + 'b'}>المجموع</Table.HeaderCell>
                    {/* <Table.Cell className="cellClass" key={index + value.id + 'b2'}>{value.status}</Table.Cell> */}


                    <Table.HeaderCell className="cellClass" key={index + 'd'}>
                        <SubCellTable data={((parseFloat(totalInHours) + parseFloat(totalOutHours) + parseFloat(totalOutDays)).toFixed(2)) + " km"}></SubCellTable>
                    </Table.HeaderCell>
                    <Table.HeaderCell className="cellClass" key={index + 'e'}>
                        <SubCellTable data={totalInHours.toFixed(2) + " km"}></SubCellTable>
                    </Table.HeaderCell>
                    <Table.HeaderCell className="cellClass" key={index + 'f'}>
                        <SubCellTable data={totalOutHours.toFixed(2) + " km"}></SubCellTable>
                    </Table.HeaderCell>
                    <Table.HeaderCell className="cellClass" key={index + 'g'}>
                        <SubCellTable data={totalOutDays.toFixed(2) + " km"}></SubCellTable>
                    </Table.HeaderCell>

                </Table.Row>
            )
        }
    }
    // array.push(

    // )
    // }
    // let random = Math.floor(Math.random() * colors.length)
    //    return <Table celled className="myTable" color={colors[random]} key={colors[random]} inverted>
    // console.log(coloridx)
    // console.log(colors[coloridx])
    return <Table celled className="myTable" style={{backgroundColor: colors[coloridx]}}  key={colors[coloridx]} inverted>
        <Table.Header  >
            <Table.Row  >
                <Table.HeaderCell className="cellClass" colSpan='1'>اسم المركبة</Table.HeaderCell>
                <Table.HeaderCell className="cellClass" colSpan='2'>{vehicle.name}</Table.HeaderCell>
                <Table.HeaderCell className="cellClass" colSpan='1'>{
                    "بداية الفترة: " + moment(from).format('YYYY-MM-DD')
                }</Table.HeaderCell>
                <Table.HeaderCell className="cellClass" colSpan='2'>{
                    "نهاية الفترة: " + moment(to).format('YYYY-MM-DD')
                }</Table.HeaderCell>

            </Table.Row>
            <Table.Row>
                <Table.HeaderCell className="cellClass">التاريخ</Table.HeaderCell>
                <Table.HeaderCell className="cellClass">اليوم</Table.HeaderCell>
                <Table.HeaderCell className="cellClass">المسافة الكلية</Table.HeaderCell>
                <Table.HeaderCell className="cellClass">المسافة في وقت الدوام</Table.HeaderCell>
                <Table.HeaderCell className="cellClass">المسافة خارج وقت الدوام</Table.HeaderCell>
                <Table.HeaderCell className="cellClass">المسافة خارج ايام الدوام</Table.HeaderCell>
            </Table.Row>

        </Table.Header>
        <Table.Body>
            {array}
        </Table.Body>
        <Table.Footer>
            {footer}
        </Table.Footer>
    </Table>
}


export default TableExamplePagination