import React from "react";
import { BrowserRouter, Route, Switch, Router } from "react-router-dom";
import "./App.css";
// import XLSX from 'xlsx';
import Context from "./components/context";
import MyNav from "./components/MyNav";
import wenkLogo from "./assets/img/wenklogo.png"
import { saveAs } from "file-saver";
import { tsConstructorType } from "@babel/types";
import moment from 'moment';
const ExcelJS = require("exceljs");

const fileJSON = require("./assets/files/constData.json");

/* global wialon */
/* global xlsx */

const RES_NAME = fileJSON.resourceName;
const TARGET_TYPES = fileJSON.targetTypes;
const REPORTS = fileJSON.repoerMaping
const host = "https://hst-api.wialon.com"
class App extends React.Component {
  constructor(props) {
    // console.log(fileJSON.targetTypes[1])
    super(props);
    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date();
    endDate.setHours(23, 59, 59, 59);

    this.state = {
      token:
        "3a49a28c7fca9ad2f6ec7866de6f12de8BC7776F03D1D3C87C27FEAEEBD54221C9D9EA8B", // bekhteari report (api_report)
      // "bc98c8124aff4fc99f29c16781eaedd028D93FF0D0B45FE517D6DA984A38A9D20E9DC357", // برنامج الوزن الخارجي 
      // "92da88d4a9fe4539ca7c0c2f45de6bb0269F742F7E790FBBF5D8C9394BAAEE5E230530E2",// mizan-api 
      //  "e6d87ef6494edaa111f9a136da77b8c069EAA33B7C6B7266814BEFB7B6689973B24D3B45", // test app raaftsalih
      isAuthorized: false,
      username: "",
      res: [],
      logging: true,
      showAlert: false,
      alertVariant: "success",
      error: false,
      alertMSG: "",
      resultJSON: [],
      selectedRes: {},
      selectedReport: {},
      selectedObject: {},
      units: [],
      unitGroups: [],
      accounts: [],
      executing: false,
      exporting: false,
      fromDateVal: startDate,
      toDateVal: endDate,
      fromDateValUnix: this.convertSATToUnix(startDate),
      toDateValUnix: this.convertSATToUnix(endDate),
      targetTypes: TARGET_TYPES,
      selectedTargetType: fileJSON.targetTypes[0], //fileJSON.targetTypes[1], // group is default
      reports: REPORTS,
      selectedReportNew: fileJSON.repoerMaping[0],
      newData: { data: [] }, // for unit [{},{},....] , for group [[{},{},....],[],[]]
      WorkInHours_Data: { data: [] },
      WorkOutHours_Data: { data: [] },
      WorkOutDays_Data: { data: [] },
      tempData: [], // to hold prepared object arrays of final project
      overlayText: "جاري تسجيل الدخول...",
      loginMode: fileJSON.loginMode,
      isTried: false,
      dataTable: [],
      zones: [],
      filteredZones: [],
      zonesGroups: [],
      selectedZone: {},
      zoneGroup: {},
      isReady: false,
      timeLimitBefore: 150,
      timeLimitAfter: 120
    };
    this.fromDateOnChange = this.fromDateOnChange.bind(this);
    this.toDateOnChange = this.toDateOnChange.bind(this);
    this.propOpenFunc = this.propOpenFunc.bind(this);
    this.execute = this.execute.bind(this);
    this.onTargetTypeChange = this.onTargetTypeChange.bind(this);
    this.onAccountChange = this.onAccountChange.bind(this);
    //  this.onUnitChange = this.onUnitChange.bind(this);
    this.onUnitGroupChange = this.onUnitGroupChange.bind(this);
    this.export = this.export.bind(this);
    this.setAuthHash = this.setAuthHash.bind(this);
    //  this.start = this.start.bind(this);
    this.auth = this.auth.bind(this);
    // this.onZoneChange = this.onZoneChange.bind(this);
  }

  render() {
    return (
      <BrowserRouter basename="/">
        <Context.Provider
          value={{
            value: this.state,
            actions: {
              fromDateOnChange: this.fromDateOnChange,
              toDateOnChange: this.toDateOnChange,
              propOpen: this.propOpenFunc,
              execute: this.execute,
              onTargetTypeChange: this.onTargetTypeChange,
              onAccountChange: this.onAccountChange,
              //  onUnitChange: this.onUnitChange,
              onUnitGroupChange: this.onUnitGroupChange,
              export: this.export,
              setAuthHash: this.setAuthHash,
              //  start: this.start,
              auth: this.auth,
              onZoneChange: this.onZoneChange
            }
          }}
        >
          <Switch>
            <Route path="/start" exact component={MyNav} />
            <Route path="/" component={MyNav} />
          </Switch>
        </Context.Provider>
      </BrowserRouter>
    );
  }
  // async start(e) {
  //   //console.log('hash updated now... going to /')
  //   //await this.setState({authHash:hash})
  //   window.location.href = "/?authHash=09fe3bad5c5671d8c13b9b21bfc6e787";
  // }

  async componentDidMount() {
    // this.auth()
  }

  setAuthHash(aHashVal) {
    // console.log('h updated')
    this.setState({ authHash: aHashVal });
    // console.log('s c')
  }

  /**
   * will be called from MyNav after checking token or hash
   * @param {*} e 
   */
  // async auth1(e) {
  //   // console.log('passed hash')
  //   // console.log(e)

  //   this.auth(e);
  // }

  async auth(hashOrToken,baseUrl) {
    //await this.setState({ token: JSON.parse(localStorage.getItem('wenk_helper_token')), logging: true })
    
    wialon.core.Session.getInstance().initSession(host);
    // Try to login when component mount
    if (this.state.loginMode === "token") {
      // console.log('mode : T')
      this.loginWithToken();
    }
    if (this.state.loginMode === "authHash") {
      // console.log('mode : H')
      this.loginWithHash(hashOrToken);
    }
  }

  loginWithToken() {
    let that = this;
    wialon.core.Session.getInstance().initSession(host);
    wialon.core.Session.getInstance().loginToken(
      this.state.token,
      "", // operate as
      async code => {
        // login callback
        // if error code - print error message
        this.setState({ isTried: true });
        if (code) {
          that.setState({
            showAlert: true,
            alertVariant: "error",
            logging: false,
            isAuthorized: false,
            alertMSG: true,
            error: true,
            alertMSG:
              code === 4
                ? "دخول غير مخول"
                : wialon.core.Errors.getErrorText(code)
          });
          return;
        }
        // console.log('authorized')
        await that.setState({
          alertVariant: "success",
          overlayText: "جاري تحميل البيانات....",
          logging: false,
          executing: false,
          error: false,
          showAlert: true,
          isAuthorized: true,
          alertMSG:
            wialon.core.Session.getInstance()
              .getCurrUser()
              .getName() + " : تم تسجيل الدخول بنجاح!",
          username: wialon.core.Session.getInstance().getCurrUser(),
          isTried: true
        });

        await that.init();
      }
    );
  }

  loginWithHash(hash) {
    wialon.core.Session.getInstance().initSession(host);

    let that = this;
    wialon.core.Session.getInstance().loginAuthHash(
      hash,
      "", // try to login
      async code => {
        this.setState({ isTried: true });

        // login callback
        // if error code - print error message
        if (code) {
          that.setState({
            showAlert: true,
            alertVariant: "error",
            logging: false,
            isAuthorized: false,
            alertMSG: true,
            error: true,
            alertMSG: wialon.core.Errors.getErrorText(code)
          });
          return;
        }
        that.setState({
          alertVariant: "success",
          overlayText: "جاري تحميل البيانات....",
          logging: false,
          executing: false,
          error: false,
          showAlert: true,
          isAuthorized: true,
          alertMSG:
            wialon.core.Session.getInstance()
              .getCurrUser()
              .getName() + " : تم تسجيل الدخول بنجاح!",
          username: wialon.core.Session.getInstance().getCurrUser(),
          isTried: true
        });

        await that.init();
      }
    );
  }

  init() {
    // console.log('start init')
    let that = this;
    var sess = wialon.core.Session.getInstance();

    sess.loadLibrary("resourceReports");
    //  sess.loadLibrary("resourceZones");
    //  sess.loadLibrary("resourceZoneGroups");

    // flags to specify what kind of data should be returned
    let res_flags =
      wialon.item.Item.dataFlag.base |
      wialon.item.Resource.dataFlag.reports// |
    //   wialon.item.Resource.dataFlag.zoneGroups |
    //   wialon.item.Resource.dataFlag.zones; // 64 bit OR
    let unit_flags = 1;
    let unitGroup_flags = 1;

    sess.updateDataFlags(
      // load items to current session
      [
        { type: "type", data: "avl_resource", flags: res_flags, mode: 0 },
        { type: "type", data: "avl_unit_group", flags: unit_flags, mode: 0 },
        { type: "type", data: "avl_unit", flags: unitGroup_flags, mode: 0 }
      ], // Items specification

      function (code) {
        // updateDataFlags callback
        if (code) {
          // console.log("Error: " + wialon.core.Errors.getErrorText(code));
          return; // exit if error code
        }

        const resourcesArr = sess.getItems("avl_resource"); // get loaded 'avl_resource's items
        // console.log("Res")
        // console.log(resourcesArr)
        that.setState({ res: resourcesArr });
        /**
         * select report at certain resource (set state with selected resource and report object)
         */
        that.findResource(resourcesArr);

        const unitGroups = sess.getItems("avl_unit_group");
        const units = sess.getItems("avl_unit");
        // console.log(units)
        // console.log(unitGroups)
        if (unitGroups.length === 0 && units.length === 0) {
          that.setState({
            showAlert: true,
            error: true,
            alertMSG: "لا توجد مجموعات او مركبات!",
            alertVariant: "error"
          });
          return;
        }

        that.setState({
          units: units,
          unitGroups: unitGroups,
          //selectedObject:unitGroups.length>0?unitGroups[0]:{},
          overlayText: ""
        });
      //  console.log(unitGroups.length)
        
      }
    );
  }

  /**
   * search for report name from the constData json file then get data from it (zones and groups of zones)
   * @param {*} res 
   * @returns 
   */
  findResource(res) {
    if (RES_NAME === "*") {
      for (let i = 0; i < res.length; i++) {
        let that = this;
        if (res[i].$$user_reports) {
          for (let [key, value] of Object.entries(res[i].$$user_reports)) {
            // if (value.n.includes(that.state.selectedTargetType.reportName)) {
            // console.log(that.state.selectedReportNew.name)
            if (value.n.includes(that.state.selectedReportNew.name)) {
              // console.log("Found a report at resource index " + i + " named " + res[i].$$user_name)
              // default selected target type should be group
              that.setState({
                selectedRes: res[i],
                selectedReport: value
              });
              break;
            }
          }
        }
      }
    }
  }


  execute(e) {
    if (
      Object.entries(this.state.selectedRes).length === 0 &&
      this.state.selectedRes.constructor === Object
    ) {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: "يرجى اختيار المصدر",
        alertVariant: "error"
      });
      return;
    }
    if (
      Object.entries(this.state.selectedReport).length === 0 &&
      this.state.selectedReport.constructor === Object
    ) {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: "يرجى اختيار التقرير",
        alertVariant: "error"
      });
      return;
    }
    if (
      Object.entries(this.state.selectedObject).length === 0 &&
      this.state.selectedObject.constructor === Object
    ) {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: "يرجى اختيار الهدف",
        alertVariant: "error"
      });
      return;
    }
    // if (
    //   Object.entries(this.state.selectedZone).length === 0 &&
    //   this.state.selectedZone.constructor === Object
    // ) {
    //   this.setState({
    //     showAlert: true,
    //     error: true,
    //     alertMSG: "يرجى اختيار المنطقة",
    //     alertVariant: "error"
    //   });
    //   return;
    // }
    if (this.state.fromDateValUnix === "" || this.state.toDateValUnix === "") {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: " يرجى اختيار الفترة اولاً",
        alertVariant: "error"
      });
      return;
    }

    let interval = {
      from: this.state.fromDateValUnix,
      to: this.state.toDateValUnix,
      flags: wialon.item.MReport.intervalFlag.absolute
    };
    let that = this;
    let sess = wialon.core.Session.getInstance();
    let resou = sess.getItem(this.state.selectedRes._id);
    let template = resou.getReport(this.state.selectedReport.id);

    // first i need to set local datetime

    // this.prepareTempData(this.state.selectedObject);
    this.setState({
      isReady: false,
      executing: true,
      overlayText:
        "جاري تنفيذ التقرير... يرجى الانتظار... قد تستغرف عملية التنفيذ اكثر من 3 دقائق اذا كانت الفترة المحددة طويلة"
    });
    let localObj = {
      flags: 0,
      formatDate: "%25Y-%25m-%25E %25H:%25M:%25S"
    };
    sess.getRenderer().setLocale(134228528, "en", localObj, function (code) {
      if (code) {
        that.setState({
          showAlert: true,
          error: true,
          alertMSG: wialon.core.Errors.getErrorText(code),//"جدول البيانات فارغ, لا توجد بيانات",
          alertVariant: "error",
          executing: false,
          overlayText: "",
          isReady: false
        });
        return;
      }
      resou.execReport(
        template,
        that.state.selectedObject._id,
        0,
        interval,
        function (code, data) {
          if (code) {
            that.setState({
              showAlert: true,
              error: true,
              alertMSG: wialon.core.Errors.getErrorText(code),
              alertVariant: "error"
            });
            return;
          }
          if (!data.getTables().length) {
            //console.log(data)
            that.setState({
              showAlert: true,
              error: true,
              alertMSG: "جدول البيانات فارغ, لا توجد بيانات",
              alertVariant: "error",
              executing: false,
              overlayText: "",
              isReady: false
            });
            return;
          }
          // console.log(that.state)
          // that.setState({newData:[],tempData:[], overlayText: 'جاري بناء التقرير' })

          that.preBuild(data);
        }
      );
    });
  }

  preBuild(dataTable) {
    let tables = dataTable.getTables();
    let that = this;
    let work_in_hours_idx = tables.findIndex(function (e) {
      return (
        e.label.includes("داخل اوقات العمل")
        // && e.label.includes(that.state.selectedZone.n)
      );
    });

    let work_out_hours_idx = tables.findIndex(function (e) {
      return (
        e.label.includes("خارج اوقات العمل")
        // && e.label.includes(that.state.selectedZone.n)
      );
    });

    let work_out_days_idx = tables.findIndex(function (e) {
      return (
        e.label.includes("خارج ايام العمل")
        // && e.label.includes(that.state.selectedZone.n)
      );
    });

    let work_in_hours_tbl = {};
    if (work_in_hours_idx >= 0) {
      work_in_hours_tbl = tables[work_in_hours_idx];
    }


    let work_out_hours_tbl = {};
    if (work_out_hours_idx >= 0) {
      work_out_hours_tbl = tables[work_out_hours_idx];
    }

    let work_out_days_tbl = {};
    if (work_out_days_idx >= 0) {
      work_out_days_tbl = tables[work_out_days_idx];
    }


    let obj = {
      inH: {
        table: work_in_hours_tbl,
        index: work_in_hours_idx
      },
      outH: {
        table: work_out_hours_tbl,
        index: work_out_hours_idx
      },
      outD: {
        table: work_out_days_tbl,
        index: work_out_days_idx
      },

    };

    this.build(dataTable, obj);
  }

  build(data, obj) {
    /**
     * obj.inH.table
     * obj.inH.index
     * 
     * same for
     * obj.outH and obj.outD
     */
    let wInH = [];
    let wOutH = [];
    let wOutD = [];

    let that = this;

    let reqObj = {
      type: "range",
      data: {
        from: 0,
        to: obj.inH.table.rows,
        level: 5,
        flat: 0,
        rawValues: 0
      }
    };

    data.selectRows(obj.inH.index, reqObj, function (code, col) {
      if (code && obj.inH.index !== -1) {
        // means the error is real, then stop the detching
        that.setState({
          showAlert: true,
          error: true,
          alertMSG: wialon.core.Errors.getErrorText(code),
          alertVariant: "error"
        });
        return;
      }

      wInH = col;
      // console.log("visit array")
      // console.log(col)

      // fetch again
      reqObj = {
        type: "range",
        data: {
          from: 0,
          to: obj.outH.table.rows,
          level: 5,
          flat: 0,
          rawValues: 0
        }
      };
      data.selectRows(obj.outH.index, reqObj, function (code2, col2) {
        if (code2 && obj.outH.index !== -1) {
          that.setState({
            showAlert: true,
            error: true,
            alertMSG: wialon.core.Errors.getErrorText(code2),
            alertVariant: "error"
          });
          return;
        }
        wOutH = col2;
        // fetch again
        reqObj = {
          type: "range",
          data: {
            from: 0,
            to: obj.outH.table.rows,
            level: 5,
            flat: 0,
            rawValues: 0
          }
        };
        data.selectRows(obj.outD.index, reqObj, function (code3, col3) {
          if (code3 && obj.outD.index !== -1) {
            that.setState({
              showAlert: true,
              error: true,
              alertMSG: wialon.core.Errors.getErrorText(code2),
              alertVariant: "error"
            });
            return;
          }
          wOutD = col3;


          // if (that.state.selectedTargetType.id === 1) {
          // groups

          that.fillWorkInHours(wInH);
          that.fillWorkOutHours(wOutH);
          that.fillWorkOutDays(wOutD);
          that.constructTable()
          // that.pairVisitWeight();
          that.setState({
            executing: false,
            overlayText: "",
            isReady: true
          });
          // }
          // if (that.state.selectedTargetType.id === 0) {
          //   // unit
          //   that.fillVisitsForUnit(obj.visit.table.total, visitsArr); // data is datatable

          //   that.setState({
          //     executing: false,
          //     overlayText: "",
          //     isReady: true
          //   });
          // }
          // that.calculateStatus();
        });
      });
    });
  }

  fillWorkInHours(dataArray) {
    // let finalArr = this.state.tempData; // get temp array data
    let finalArr = []
    //  console.log(this.state.tempData)
    // console.log(dataArray)
    for (let i = 0; i < dataArray.length; i++) {
      let unitName = dataArray[i].c[1]; // get unit Name
      let r = dataArray[i].r;
      //start with visits in marakez

      let wInHObj = {
        //   t1: visitsArr[i].c[2],
        //    t2: visitsArr[i].c[3],
        // totalKM: visitsArr[i].c[4],
        // totalDuration: visitsArr[i].c[5],
        // count: visitsArr[i].c[6],

        unitName: unitName,
        totalKm: dataArray[i].c[2],
        records: []
      };
      // console.log(' r of visits array');
      // console.log(r)
      for (let j = 0; j < r.length; j++) {

        // console.log(dtArr4)
        let detailsObj = {
          date: r[j].c[1],
          km: r[j].c[2]
        };
        // console.log('details obj')
        // console.log(detailsObj)
        wInHObj.records.push(detailsObj);
      }
      // console.log("visit obj")
      // console.log(visitsObj)
      // now update object of unit in final array

      // let index = finalArr.findIndex(e => e.unitName === unitName);
      // if (index >= 0) {
      //   finalArr[index].visits = visitsObj;
      // } else {
      //   // console.log('not found')
      // }
      finalArr[i] = wInHObj

    }
    this.setState({ WorkInHours_Data: finalArr });
  }

  fillWorkOutHours(dataArray) {
    let finalArr = []
    // console.log(dataArray)
    for (let i = 0; i < dataArray.length; i++) {
      let unitName = dataArray[i].c[1]; // get unit Name
      let r = dataArray[i].r;

      let wOutHObj = {
        unitName: unitName,
        totalKm: dataArray[i].c[2],
        records: []
      };

      for (let j = 0; j < r.length; j++) {
        let detailsObj = {
          date: r[j].c[1],
          km: r[j].c[2]
        };
        wOutHObj.records.push(detailsObj);
      }
      finalArr[i] = wOutHObj
    }
    this.setState({ WorkOutHours_Data: finalArr });
  }

  fillWorkOutDays(dataArray) {
    let finalArr = []
    // console.log(dataArray)
    for (let i = 0; i < dataArray.length; i++) {
      let unitName = dataArray[i].c[1]; // get unit Name
      let r = dataArray[i].r;

      let wOutDObj = {
        unitName: unitName,
        totalKm: dataArray[i].c[2],
        records: [{}]
      };

      for (let j = 0; j < r.length; j++) {
        let detailsObj = {
          date: r[j].c[1],
          km: r[j].c[2]
        };
        wOutDObj.records.push(detailsObj ? detailsObj : {});
      }
      finalArr[i] = wOutDObj
    }
    this.setState({ WorkOutDays_Data: finalArr });
  }

  translateDay(day) {
    switch (day) {
      case 0: return "الاحد";
      case 1: return "الاثنين";
      case 2: return "الثلاثاء";
      case 3: return "الاربعاء";
      case 4: return "الخميس";
      case 5: return "الجمعة";
      case 6: return "السبت";
      default: return "غير معلوم"
    }
  }

  constructTable() {
    let that = this
    let finalArray = []
    let vehiclesArray = []
    let rowObj = {
      date: "",
      day: "",
       totalKm:0,
      wInHkm: 0,
      wOutHkm: 0,
      wOutDkm: 0
    }//raafat
    let wInH_arr = this.state.WorkInHours_Data
    let wOutH_arr = this.state.WorkOutHours_Data
    let wOutD_arr = this.state.WorkOutDays_Data
    for (var record in wInH_arr) {
      vehiclesArray.push(wInH_arr[record].unitName)
    }

    let difference = this.state.toDateValUnix - this.state.fromDateValUnix;
    let TotalDays = Math.ceil(difference / (3600 * 24));
    for (var i = 0; i < vehiclesArray.length; i++) {
      let datesArray = []
      let totalDistancePerVehicle = 0
      let totalwInHkm = 0
      let totalwOutHkm = 0
      let totalwOutDkm = 0
      for (var j = 0; j < TotalDays; j++) {
        var workDate = new Date((this.state.fromDateValUnix + (j * 86400)) * 1000)
        //    console.log(wInH_arr[i].records)
        var foundWInHkm = "---"
        var foundWOutHkm = "---"
        var foundWOutDkm = "---"
        //  if (wInH_arr[i].length > 0)
        for (var k = 0; k < wInH_arr[i].records.length; k++) {
          var date = Date.parse(wInH_arr[i].records[k].date) - 10800000
          if (Date.parse(workDate) == date) {
            foundWInHkm = wInH_arr[i].records[k].km
            break
          }
        }
        //  if (wOutH_arr[i].length > 0)
        for (var k = 0; k < wOutH_arr[i].records.length; k++) {
          var date = Date.parse(wOutH_arr[i].records[k].date) - 10800000
          if (Date.parse(workDate) == date) {
            foundWOutHkm = wOutH_arr[i].records[k].km
            break
          }
        }
        //   if (wOutD_arr[i].length > 0)
        for (var k = 0; k < wOutD_arr[i].records.length; k++) {
          var date = Date.parse(wOutD_arr[i].records[k].date) - 10800000
          if (Date.parse(workDate) == date) {
            foundWOutDkm = wOutD_arr[i].records[k].km
            break
          }
        }
        let var1 = foundWInHkm.replace(/[^\d\.]*/g, '');
        let var2 = foundWOutHkm.replace(/[^\d\.]*/g, '');
        let var3 = foundWOutDkm.replace(/[^\d\.]*/g, '');
        var1 = isNaN(parseFloat(var1)) ? 0 : parseFloat(var1).toFixed(2)
        var2 = isNaN(parseFloat(var2)) ? 0 : parseFloat(var2).toFixed(2)
        var3 = isNaN(parseFloat(var3)) ? 0 : parseFloat(var3).toFixed(2)
        let totalDistancePerDay = (parseFloat(var1) + parseFloat(var2) + parseFloat(var3))
        totalDistancePerVehicle += totalDistancePerDay
        totalwInHkm += parseFloat(var1)
        totalwOutHkm += parseFloat(var2)
        totalwOutDkm += parseFloat(var3)
        let rowObj = {
          date: workDate.toLocaleDateString("en-GB"),
          day: this.translateDay(workDate.getDay()),
          totalKm: totalDistancePerDay.toFixed(2),
          wInHkm: foundWInHkm,
          wOutHkm: foundWOutHkm,
          wOutDkm: foundWOutDkm
        }//raafat
        datesArray.push(rowObj)
      }
      let vehicleOb = {
        totalKm: totalDistancePerVehicle.toFixed(2),
        totalwInHkm: totalwInHkm.toFixed(2),
        totalwOutHkm: totalwOutHkm.toFixed(2),
        totalwOutDkm: totalwOutDkm.toFixed(2),
        name: vehiclesArray[i],
        dates: datesArray
      }
      finalArray.push(vehicleOb)
    }
    /**
     * get totals
     */
    let dataArray = { data: finalArray }
    // console.log("construct Table")
    // console.log(finalArray)
    // console.log("############")
    this.setState({
      newData: dataArray
    })
  }

  fromDateOnChange(e) {
    let from = this.convertSATToUnix(e);
    if (!this.state.fromDateVal === "") {
      if (e) {
        if (this.compareTime(from, this.state.toDateValUnix)) {
          this.setState({ fromDateValUnix: from, fromDateVal: e });
        } else {
          this.setState({
            showAlert: true,
            error: true,
            alertMSG: "يجب ان تكون الفترة المحددة  صحيحة"
          });
        }
      }
    } else {
      this.setState({ fromDateValUnix: from, fromDateVal: e });
    }
  }
  toDateOnChange(e) {
    let to = this.convertSATToUnix(e);
    if (e) {
      if (this.compareTime(this.state.fromDateValUnix, to)) {
        this.setState({ toDateValUnix: to, toDateVal: e });
      } else {
        this.setState({
          showAlert: true,
          error: true,
          alertMSG: "يجب ان تكون الفترة المحددة  صحيحة"
        });
      }
    }
  }
  convertSATToUnix(sat) {
    let longVal = Math.floor(new Date(sat).getTime() / 1000);
    return longVal;
  }
  compareTime(from, to) {
    return from <= to;
  }
  propOpenFunc(e) {
    this.setState({
      showAlert: !this.state.showAlert,
      alertMSG: "",
      error: false
    });
  }

  async onTargetTypeChange(e) {
    if (e) {
      let selObj =
        e.id === 0
          ? this.state.units.length > 0
            ? this.state.units[0]
            : {}
          : this.state.unitGroups.length > 0
            ? this.state.unitGroups[0]
            : {};

      await this.setState({
        selectedTargetType: e,
        newData: { data: [] },
        selectedObject: selObj
      });
      this.findResource(this.state.res);
    }
  }

  async onAccountChange(e) {
    if (e) {

      await this.setState({
        selectedReportNew: e,
        newData: { data: [] }
      });
      this.findResource(this.state.res);
    }
  }

  // onUnitChange(e) {
  //   if (e) {
  //     this.setState({
  //       selectedObject: e,
  //       selectedTargetType: fileJSON.targetTypes[0]
  //     });
  //     // this.prepareTempData(e);
  //   }
  // }

  onUnitGroupChange(e) {
    // console.log(e)
    if (e) {
      this.setState({
        selectedObject: e,
        selectedTargetType: fileJSON.targetTypes[0]
      });
      // this.prepareTempData(e);
    }
  }

  export(e) {
    let reportName = this.state.selectedReportNew.title + " - (" + this.state.selectedObject.$$user_name + ")"
    let fileName = reportName + ".xlsx";
    let str =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let dt = this.state.newData;
    //Creating New Workbook
    let dataStartAtIdx = 6;

    let workbook = new ExcelJS.Workbook();
    //Creating Sheet for that particular WorkBook
    let sheetName = "التفاصيل";

    let sheet = workbook.addWorksheet(sheetName, {
      pageSetup: { paperSize: 9, orientation: "portrait" }, // 9 is A4 page
      properties: { showGridLines: true },
      views: [
        { state: 'frozen', ySplit: dataStartAtIdx - 1,rightToLeft:true}
      ]
    });

    this.createSheetHeader(sheet, dataStartAtIdx, dt, reportName)
    this.createSheet1(sheet, dataStartAtIdx, dt)


    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: str });
      saveAs(blob, fileName);
    });
  }

  borderAll(sheet) {
    // border all
    sheet.eachRow(function (row, rowNumber) {
      // if (rowNumber > minus1) row.style = that.styleCell();
      row.eachCell(function (cell, rowNumberCell) {
        if (rowNumberCell < 14) {
          // console.log(rowNumberCell)
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          };
          cell.alignment = { vertical: "top", horizontal: "center" };
        }
      });
    });
  }


  createSheet1(sheet, dataStartAtIdx, dt) {
    let minus4 = dataStartAtIdx - 4
    let minus3 = dataStartAtIdx - 3
    let minus2 = dataStartAtIdx - 2
    let minus1 = dataStartAtIdx - 1
    //Header must be in below format

    sheet.columns = [
      { key: "date", width: 12 },//
      { key: "day", width: 8 },
      //    { key: "status", width: 6.44 },
      { key: "total", width: 11.22 }, // c
      { key: "wHinH", width: 16.89 }, // d
      { key: "wHoutH", width: 19.11 }, // e
      { key: "wHoutD", width: 18 }, // f

    ];


    sheet.getRow(minus4).eachCell({ includeEmpty: false }, function (cell, colNumber) {
      cell.font = { size: 16, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FF0000'
        }
      };
    });
    sheet.getRow(minus3).eachCell({ includeEmpty: false }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'aaaaaa'
        }
      };
    });
    sheet.getRow(minus2).eachCell({ includeEmpty: false }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'aaaaaa'
        }
      };
    });
    sheet.getRow(minus1).eachCell({ includeEmpty: false }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'aaaaaa'
        }
      };
    });


    let index = dataStartAtIdx;
    let sheetCounter = 0 + index
    for (let i in dt.data) {

      sheet.mergeCells("A" + sheetCounter + ":F" + sheetCounter);
      sheet.getCell("A" + sheetCounter).value = dt.data[i].name

      sheet.getCell("F" + sheetCounter).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'CD7F32'
        }
      };

      sheetCounter++

      sheet.getCell("A" + sheetCounter).value = "التاريخ"
      sheet.getCell("B" + sheetCounter).value = "اليوم"
      sheet.getCell("C" + sheetCounter).value = "المسافة الكلية"
      sheet.getCell("D" + sheetCounter).value = "المسافة في وقت الدوام"
      sheet.getCell("E" + sheetCounter).value = "المسافة خارج وقت الدوام"
      sheet.getCell("F" + sheetCounter).value = "المسافة خارج ايام الدوام"
      sheet.getCell("A" + sheetCounter).font = { bold: true };
      sheet.getCell("B" + sheetCounter).font = { bold: true };
      sheet.getCell("C" + sheetCounter).font = { bold: true };
      sheet.getCell("D" + sheetCounter).font = { bold: true };
      sheet.getCell("E" + sheetCounter).font = { bold: true };
      sheet.getCell("F" + sheetCounter).font = { bold: true };

      sheetCounter++

      for (let j = 0 + index, counter = 0; j < dt.data[i].dates.length + index; j++) {
        sheet.getCell("A" + sheetCounter).value = dt.data[i].dates[counter].date
        sheet.getCell("B" + sheetCounter).value = dt.data[i].dates[counter].day
        sheet.getCell("C" + sheetCounter).value = dt.data[i].dates[counter].totalKm + " km"
        sheet.getCell("D" + sheetCounter).value = dt.data[i].dates[counter].wInHkm
        sheet.getCell("E" + sheetCounter).value = dt.data[i].dates[counter].wOutHkm 
        sheet.getCell("F" + sheetCounter).value = dt.data[i].dates[counter].wOutDkm
        for (let k = 1; k < 7; k++) {
          if (sheet.getRow(sheetCounter).getCell(k).value === "---") {
            sheet.getRow(sheetCounter).getCell(k).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: 'FFD580'
              }
            };
          }
        }
        counter++
        sheetCounter++
      }
      // sheet.getCell("A" + sheetCounter).value = dt.data[i].
      // sheet.getCell("B" + sheetCounter).value = dt.data[i].
      sheet.mergeCells("A" + sheetCounter + ":B" + sheetCounter);
      sheet.getCell("A" + sheetCounter).value = "المجـــــموع"
      sheet.getCell("C" + sheetCounter).value = dt.data[i].totalKm + " km"
      sheet.getCell("D" + sheetCounter).value = dt.data[i].totalwInHkm + " km"
      sheet.getCell("E" + sheetCounter).value = dt.data[i].totalwOutHkm + " km"
      sheet.getCell("F" + sheetCounter).value = dt.data[i].totalwOutDkm + " km"
      for (let k = 1; k < 7; k++) {
      //  if (sheet.getRow(sheetCounter).getCell(k).value === "---") {
          sheet.getRow(sheetCounter).getCell(k).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'D5F5E3'
            }
          };
       // }
      }
      sheetCounter++


    }
    this.borderAll(sheet)
  }


  createSheetHeader(sheet, dataStartAtIdx, dt, reportName) {
    let minus5 = dataStartAtIdx - 5
    let minus4 = dataStartAtIdx - 4
    let minus3 = dataStartAtIdx - 3

    let minus2 = dataStartAtIdx - 2
    let minus1 = dataStartAtIdx - 1

    sheet.getRow(minus3).values = [
      "نظام وينك لتعقب المركبات",
      "نظام وينك لتعقب المركبات",
      "نظام وينك لتعقب المركبات",
      "شركة ركن الحلول المتكاملة",
      "شركة ركن الحلول المتكاملة",
      "شركة ركن الحلول المتكاملة"
    ]

    sheet.getRow(minus2).values = [
      "وقت تنفيذ التقرير: " + moment().format('YYYY/MM/DD - HH:mm'),
      "وقت تنفيذ التقرير: " + moment().format('YYYY/MM/DD - HH:mm'),
      "وقت تنفيذ التقرير: " + moment().format('YYYY/MM/DD - HH:mm'),

      "عدد المركبات = " + dt.data.length,
      "عدد المركبات = " + dt.data.length,
      "عدد المركبات = " + dt.data.length,

    ]
    sheet.getRow(minus1).values = [
      "نهاية الفترة: " + moment(this.state.toDateVal).format('YYYY-MM-DD'),
      "نهاية الفترة: " + moment(this.state.toDateVal).format('YYYY-MM-DD'),
      "نهاية الفترة: " + moment(this.state.toDateVal).format('YYYY-MM-DD'),

      "بداية الفترة: " + moment(this.state.fromDateVal).format('YYYY-MM-DD'),
      "بداية الفترة: " + moment(this.state.fromDateVal).format('YYYY-MM-DD'),
      "بداية الفترة: " + moment(this.state.fromDateVal).format('YYYY-MM-DD')
    ]






    sheet.mergeCells("A1:F2"); // merge name vertically // title of report

    // sheet.mergeCells("A" + minus2 + ":F" + minus2); // mergce center details
    // sheet.mergeCells("G" + minus2 + ":H" + minus2); // merge eh
    sheet.mergeCells("E" + minus1 + ":F" + minus1); // شحنات مع وزن
    sheet.mergeCells("A" + minus1 + ":D" + minus1); // الاوزان الغير مرتبطة برحلة
    sheet.mergeCells("E" + minus2 + ":F" + minus2); // شحنات مع وزن
    sheet.mergeCells("A" + minus2 + ":D" + minus2); // الاوزان الغير مرتبطة برحلة
    sheet.mergeCells("A" + minus3 + ":D" + minus3); //  نظام وينك لتعقب المركبات
    sheet.mergeCells("E" + minus3 + ":F" + minus3); //  شركة ركن الحلول المتكاملة


    // يجب اضافة وقت تنفيذ التقرير + المركبات الموزنة بدون ارتباط بشحنات + تفاصيل الوزن


    sheet.getCell("A1").value = reportName;
    sheet.getCell("A1").font = { size: 18, bold: true };
    sheet.getCell("A1").border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" }
    };
    sheet.getCell("A1").alignment = {
      vertical: "middle",
      horizontal: "center"
    };

    sheet.getRow(minus3).font = { size: 11, bold: true };
    sheet.getRow(minus4).font = { size: 11, bold: true };
    // sheet.getRow(minus5).font = { size: 13, bold: true };


  }

  styleCell() {
    let style = {
      font: { size: 10, bold: false, name: "Arial" },
      alignment: { vertical: "middle", horizontal: "center" },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      }
    };
    return style;
  }


}

export default App;
